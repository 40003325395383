import * as React from "react"
import Layout from "../components/layout";
import * as Container from "../components/container/container";

const NotFoundPage = () => {
    return (
        <Layout pageTitle="OdD" props={{path: '404'}}>
            <main id="main">
                <Container.Part background="white">
                    <Container.Row>
                        <div className="col-12">
                            <h1 className="">
                                Seite nicht gefunden
                            </h1>
                            <p>Die von Ihnen gesuchte Seite wurde nicht gefunden.
                            </p>
                            <p>Entweder existiert diese Seite nicht mehr oder Sie haben sich bei der Adresse vertippt.
                            </p>
                            <p>Bitte verwenden Sie die Navigation oder um zu Ihrem gewünschten Inhalt zu gelangen.</p>
                        </div>
                    </Container.Row>
                </Container.Part>
            </main>
        </Layout>
    )
}

export default NotFoundPage
